import Select from "react-select";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import {
    Icon,
    jnjPlay,
    jnjPause,
    jnjReplay,
    jnjBack10Seconds,
    jnjSkip10Seconds,
} from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { debounce } from "@jmc/utils/utils/debounce";
import { convertSecondsToTimeString } from "@jmc/utils/utils/date-to-string";
import classnames from "classnames";
import { BreakpointMap } from "../../../utils/breakpoints";
import { VideoPlayer } from "@jmc/solid-design-system/src/components/organisms/VideoPlayer/VideoPlayer";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { mdiPlay, mdiPause, mdiReplay } from "@mdi/js";
import ResizeObserver from "@zeecoder/react-resize-observer";
import { useLocale } from "@jmc/core/src/hooks/useLocale/index";
import { getLocaleRtl } from "@jmc/solid-design-system/src/utils/languages";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import style from "./style.module.scss";

interface Props {
    src: string;
    /** Check time when paused and send to Parent element Cardimpression */
    mediastop?: (playing: boolean, currentTime: number | string) => void;
    /** Get total length time */
    getLength?: (duration: number) => void;
    /** Get current time in seconds */
    getCurrentTime?: (currentTime: number) => void;
    brightcove_id?: string;
    isPlayerAlwaysExpanded?: boolean;
    playButtonAriaLabel?: string;
    pauseButtonAriaLabel?: string;
    replayButtonAriaLabel?: string;
    backButtonAriaLabel?: string;
    forwardButtonAriaLabel?: string;
    isOnDetailPage?: boolean;
    downloadLink?: JSX.Element;
}

const AudioBreakpoint: BreakpointMap = {
    "player-small": 260,
    "player-large": 640,
};

export const AudioPlayerComponent = (props: Props): JSX.Element => {
    const {
        src,
        getLength = null,
        getCurrentTime = null,
        mediastop = null,
        brightcove_id = null,
        isPlayerAlwaysExpanded = false, // TODO: Rebranding: Looks like this prop is always set to true
        playButtonAriaLabel = "Play",
        pauseButtonAriaLabel = "Pause",
        replayButtonAriaLabel = "Replay",
        backButtonAriaLabel = "Go back 10 seconds",
        forwardButtonAriaLabel = "Go forward 10 seconds",
        isOnDetailPage = false,
        downloadLink = null,
    } = props;

    const options = [
        { label: "1.0 x", value: 1, isSelected: true },
        { label: "1.5 x", value: 1.5, isSelected: false },
        { label: "2.0 x", value: 2, isSelected: false },
        { label: "2.5 x", value: 2.5, isSelected: false },
    ];

    const { jnjFullBranded } = useJnjBranding();
    const [paused, setPaused] = useState(true);
    const isBrowser = typeof document !== "undefined";
    const [audio] = useState(
        isBrowser
            ? props?.brightcove_id
                ? new Audio()
                : new Audio(props.src)
            : {
                  currentTime: 0,
                  duration: 1,
                  playbackRate: 1,
                  play: () => {
                      // no-op
                  },
                  pause: () => {
                      // no-op
                  },
                  addEventListener: () => {
                      // no-op
                  },
                  removeEventListener: () => {
                      // no-op
                  },
              },
    );
    const [brightcoveRef, setBrightcoveRef] = useState(null);
    const [playerExpanded, setExpandedState] = useState(isPlayerAlwaysExpanded);
    const [duration, setDuration] = useState(0);
    const [accepted, setAccepted] = useState("loading");
    const [speed, setSpeed] = useState(1);
    const [replay, setReplay] = useState(false);

    const locale = useLocale();
    const rtl = getLocaleRtl(locale);

    const timeline = useRef(null);
    const timelineHandle = useRef(null);
    const timelineFiller = useRef(null);
    const durationRef = useRef(null);
    const offsetRef = useRef(null);

    const brightcoveElement = brightcoveRef && brightcoveRef?.current.querySelector("video");

    const moveHandlePosition = (position: number): void => {
        const timelineWidth = timeline?.current?.offsetWidth - timelineHandle?.current?.offsetWidth;
        const handleStart = position < timelineWidth ? position : timelineWidth;

        if (handleStart >= 0 && timelineHandle?.current) {
            timelineHandle.current.style.marginInlineStart = handleStart + "px";
            timelineFiller.current.style.width = handleStart + 4 + "px";
        }
    };

    const updateTimelineHandle = (): void => {
        if (!playerExpanded) return;

        const ratio = brightcove_id
            ? brightcoveElement?.currentTime / brightcoveElement?.duration
            : audio.currentTime / audio.duration;
        const position = timeline?.current?.offsetWidth * ratio;
        const currentTime = (brightcove_id ? brightcoveElement?.currentTime : audio.currentTime) || 0;
        if (isBrowser && durationRef?.current) {
            durationRef.current.innerHTML = `${convertSecondsToTimeString(currentTime)}`;
        }
        if (isBrowser && offsetRef?.current) {
            offsetRef.current.innerHTML = `${convertSecondsToTimeString(duration - currentTime)}`;
        }
        if (
            duration > 0 &&
            Math.floor(duration) === Math.floor(brightcove_id ? brightcoveElement?.currentTime : audio.currentTime)
        ) {
            setReplay(true);
        }
        getCurrentTime && getCurrentTime(currentTime);
        moveHandlePosition(position);
    };

    const handleResize = (): void => {
        if (!playerExpanded) return;

        const handleStart = brightcove_id
            ? (timeline?.current?.offsetWidth - timelineHandle?.current?.offsetWidth) *
              (brightcoveElement?.currentTime / brightcoveElement?.duration)
            : (timeline?.current?.offsetWidth - timelineHandle?.current?.offsetWidth) *
              (audio.currentTime / audio.duration);

        if (timelineHandle?.current) {
            timelineHandle.current.style.marginInlineStart = handleStart + "px";
            timelineFiller.current.style.width = handleStart + 2 + "px";
        }
    };

    const debounceHandleResize = debounce(handleResize);

    const handleMetaDataLoading = (): void => {
        !brightcove_id && setDuration(audio.duration);
        !brightcove_id && getLength && getLength(audio.duration);
    };

    useEffect(() => {
        if (isBrowser && offsetRef?.current) {
            offsetRef.current.innerHTML = `${convertSecondsToTimeString(duration)}`;
        }
    }, [duration]);

    useEffect(() => {
        brightcove_id && brightcoveElement
            ? brightcoveElement.addEventListener("timeupdate", updateTimelineHandle)
            : audio.addEventListener("timeupdate", updateTimelineHandle);
        window.addEventListener("resize", debounceHandleResize);
        audio.addEventListener("loadedmetadata", handleMetaDataLoading);
        setRate(speed, false);

        return (): void => {
            brightcove_id && brightcoveElement
                ? brightcoveElement.removeEventListener("timeupdate", updateTimelineHandle)
                : audio.removeEventListener("timeupdate", updateTimelineHandle);
            window.removeEventListener("resize", debounceHandleResize);
            audio.removeEventListener("loadedmetadata", handleMetaDataLoading);
        };
    });

    useEffect(() => {
        const scriptStatus = (): void => {
            // 3 correspond to functional cookies being accepted in optanonactivegroups
            const activeGroups = (window as any)?.OptanonActiveGroups as string;
            const currentStatus =
                activeGroups?.split(",")?.includes("3") || activeGroups === undefined ? "ready" : "blocked";
            setAccepted(currentStatus);
        };

        scriptStatus();
        window.addEventListener("OptanonActiveGroupsChanged", scriptStatus);
        return () => {
            window.removeEventListener("OptanonActiveGroupsChanged", scriptStatus);
        };
    });

    const resetPlayer = (): void => {
        setReplay(false);
        if (brightcoveElement) {
            brightcoveElement.currentTime = 0;
        } else {
            audio.currentTime = 0;
        }
        // Delay play to make sure time is set correctly
        setTimeout(() => {
            playPause();
        }, 200);
    };

    const playPause = (): void => {
        const currentTime = (brightcove_id ? brightcoveElement?.currentTime : audio.currentTime) || 0;
        if (paused) {
            mediastop && mediastop(true, currentTime);
            setPaused(false);
            !brightcove_id && audio.play();
            !isPlayerAlwaysExpanded && setExpandedState(true);
        } else {
            mediastop && mediastop(false, currentTime);
            setPaused(true);
            !brightcove_id && audio?.pause();
            !isPlayerAlwaysExpanded && setExpandedState(false);
        }
    };

    const setRate = (rate: number | string, saveRate: boolean) => {
        const rateFloat = typeof rate === "number" ? rate : parseFloat(rate);
        if (!isNaN(rateFloat)) {
            if (brightcoveElement && brightcoveElement?.playbackRate !== rateFloat) {
                brightcoveElement.playbackRate = rateFloat;
            } else {
                if (audio && audio?.playbackRate !== rateFloat) audio.playbackRate = rateFloat;
            }
            saveRate && setSpeed(rateFloat);
        }
    };

    useEffect(() => {
        document?.getElementById("speedSelect")?.setAttribute("title", options[0].label);
        return (): void => {
            !brightcove_id && audio?.pause();
        };
    }, []);

    const mouseMove = (e: { pageX: number } | React.TouchEvent<HTMLDivElement>): void => {
        let pageX = 0;
        if ("touches" in e) {
            pageX = e.touches[0].pageX;
        } else {
            pageX = e.pageX;
        }

        const timelineStartOffset = rtl
            ? timeline.current.getBoundingClientRect().left + timeline.current.getBoundingClientRect().width
            : timeline.current.getBoundingClientRect().left;
        !brightcove_id &&
            (audio.currentTime =
                ((rtl ? timelineStartOffset - pageX : pageX - timelineStartOffset) / timeline?.current?.offsetWidth) *
                audio.duration);
        const bcTime =
            ((rtl ? timelineStartOffset - pageX : pageX - timelineStartOffset) / timeline?.current?.offsetWidth) *
            brightcoveElement?.duration;
        brightcoveElement && (brightcoveElement.currentTime = bcTime);
    };

    const mouseUp = (): void => {
        window.removeEventListener("mousemove", mouseMove);
        window.removeEventListener("mouseup", mouseUp);
    };

    const mouseDown = (): void => {
        window.addEventListener("mousemove", mouseMove);
        window.addEventListener("mouseup", mouseUp);
    };

    const blocked = brightcove_id && accepted === "blocked";

    if (replay && !paused) {
        playPause();
    }

    useEffect(() => {
        if (!brightcove_id && audio.duration) {
            setDuration(audio.duration);
            getLength && getLength(audio.duration);
        }
    }, [audio.duration]);

    return (
        <ResizeObserver>
            {(ref: React.RefObject<HTMLDivElement>, width: number) => {
                const playerSmall = width < AudioBreakpoint["player-small"];
                const playerLarge = width >= AudioBreakpoint["player-large"];

                return (
                    <div
                        ref={ref}
                        className={classnames(
                            style.audioPlayer,
                            !playerExpanded && style.contracted,
                            playerExpanded && style.expanded,
                            downloadLink && jnjFullBranded && !isOnDetailPage && style.iconControl,
                        )}
                    >
                        {brightcove_id ? (
                            <div className={style.brightcove}>
                                <VideoPlayer.Brightcove
                                    videoId={brightcove_id}
                                    isAudio
                                    play={!paused}
                                    getLength={(d: number): void => {
                                        setDuration(d);
                                        getLength && getLength(d);
                                    }}
                                    playVideoRef={brightcoveRef}
                                    onPlay={(videoref: MutableRefObject<any>) => {
                                        setBrightcoveRef(videoref);
                                    }}
                                />
                            </div>
                        ) : (
                            // eslint-disable-next-line jsx-a11y/media-has-caption
                            <audio src={src} preload="none" />
                        )}

                        <div className={classnames(style.controls, blocked ? style.blocked : "")}>
                            <button
                                className={style.playPauseWrapper}
                                onClick={!blocked && (replay ? resetPlayer : playPause)}
                                aria-label={
                                    replay ? replayButtonAriaLabel : paused ? playButtonAriaLabel : pauseButtonAriaLabel
                                }
                                title={
                                    replay ? replayButtonAriaLabel : paused ? playButtonAriaLabel : pauseButtonAriaLabel
                                }
                            >
                                {replay && (
                                    <Icon
                                        className={style.playBtn}
                                        icon={jnjFullBranded ? jnjReplay : mdiReplay}
                                        type={jnjFullBranded ? "jnj" : "mdi"}
                                        size={jnjFullBranded ? "medium" : "large"}
                                        verticalAlign="middle"
                                        color="inherit"
                                    />
                                )}
                                {!replay && paused && (
                                    <Icon
                                        className={style.playBtn}
                                        icon={jnjFullBranded ? jnjPlay : mdiPlay}
                                        type={jnjFullBranded ? "jnj" : "mdi"}
                                        size={jnjFullBranded ? "medium" : "large"}
                                        verticalAlign="middle"
                                        color="inherit"
                                    />
                                )}
                                {!replay && !paused && (
                                    <Icon
                                        className={style.pauseBtn}
                                        icon={jnjFullBranded ? jnjPause : mdiPause}
                                        type={jnjFullBranded ? "jnj" : "mdi"}
                                        size={jnjFullBranded ? "medium" : "large"}
                                        verticalAlign="middle"
                                        color="inherit"
                                    />
                                )}
                            </button>
                            {playerExpanded && (
                                <div className={style.timelineWrapper}>
                                    <div
                                        className={classnames(style.timeline)}
                                        ref={timeline}
                                        onClick={mouseMove}
                                        onTouchMoveCapture={mouseMove}
                                        role="presentation"
                                    >
                                        <div className={classnames(style.timelineBackground)}>
                                            {!blocked && (
                                                <div
                                                    className={classnames(style.timelineFiller)}
                                                    ref={timelineFiller}
                                                ></div>
                                            )}
                                        </div>
                                        {!blocked && (
                                            <div
                                                className={classnames(style.timelineHandle)}
                                                ref={timelineHandle}
                                                onMouseDown={mouseDown}
                                                role="presentation"
                                            ></div>
                                        )}
                                    </div>
                                    <div className={style.underTimeline}>
                                        <div className={classnames(style.speedControls, playerSmall && style.small)}>
                                            {!playerSmall && (
                                                <button
                                                    className={classnames(style.timeControl, blocked && style.disabled)}
                                                    data-test-id={`AudioPlayer.TimeBack10s`}
                                                    aria-label={backButtonAriaLabel}
                                                    title={backButtonAriaLabel}
                                                    onClick={() => {
                                                        brightcoveElement
                                                            ? (brightcoveElement.currentTime =
                                                                  brightcoveElement.currentTime - 10)
                                                            : (audio.currentTime = audio.currentTime - 10);
                                                    }}
                                                >
                                                    <Icon
                                                        icon={rtl ? jnjSkip10Seconds : jnjBack10Seconds}
                                                        type="jnj"
                                                        color={blocked ? "disabled" : "text-light"}
                                                    />
                                                </button>
                                            )}
                                            <div
                                                className={classnames(style.speedSelect, playerSmall && style.small)}
                                                key="audio.select"
                                            >
                                                <Select
                                                    name="speed"
                                                    aria-label={`${speed.toFixed(1)} x`}
                                                    title={`${speed.toFixed(1)} x`}
                                                    id={"speedSelect"}
                                                    isDisabled={blocked}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    components={{
                                                        DropdownIndicator: () => null,
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                    onChange={(value) => {
                                                        value?.value && setRate(value?.value, true);
                                                        document
                                                            ?.getElementById("speedSelect")
                                                            ?.setAttribute("title", value?.label);
                                                    }}
                                                    styles={{
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            top: "-1px",
                                                            margin: "0",
                                                            width: "var(--spacing-72)",
                                                            left: "var(--spacing-minus-12)",
                                                            right: "var(--spacing-minus-12)",
                                                            boxShadow: "0px 16px 24px rgb(0 0 0 / 24%)",
                                                            borderBottom: 0,
                                                            borderColor: "var(--white)",
                                                        }),
                                                        menuList: (provided) => ({
                                                            ...provided,
                                                            paddingTop: "var(--spacing-S)",
                                                            paddingBottom: "var(--spacing-S)",
                                                        }),
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            outline: state.isFocused
                                                                ? "5px auto -webkit-focus-ring-color"
                                                                : null,
                                                            backgroundColor: "transparent",
                                                            borderColor: blocked
                                                                ? "var(--disabled)"
                                                                : "var(--grey-100)",
                                                            height: "var(--spacing-24)",
                                                            minHeight: "var(--spacing-24)",
                                                            boxSizing: "content-box",
                                                            boxShadow: "none",
                                                        }),
                                                        valueContainer: (provided) => ({
                                                            ...provided,
                                                            fontSize: "var(--font-size-S)",
                                                            input: {
                                                                left: 0,
                                                            },
                                                        }),
                                                        container: (provided) => ({
                                                            ...provided,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            width: "var(--spacing-48)",
                                                            height: "var(--spacing-24)",
                                                        }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            backgroundColor: state.isFocused
                                                                ? "var(--grey-light)"
                                                                : "none",
                                                            color: blocked
                                                                ? "var(--disabled-text)"
                                                                : "var(--text-light)",
                                                            fontSize: "var(--font-size-S)",
                                                            padding: "0",
                                                            marginTop: "var(--spacing-S)",
                                                            marginInline: "auto",
                                                            height: "var(--spacing-24)",
                                                            width: "var(--spacing-48)",
                                                            borderRadius: "var(--spacing-2XS)",
                                                            lineHeight: "var(--spacing-24)",
                                                            "&:active": {
                                                                backgroundColor: "var(--grey-light)",
                                                            },
                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            color: blocked
                                                                ? "var(--disabled-text)"
                                                                : "var(--text-light)",
                                                            margin: 0,
                                                        }),
                                                    }}
                                                    options={options}
                                                    defaultValue={options[0]}
                                                />
                                            </div>
                                            {!playerSmall && (
                                                <button
                                                    className={classnames(style.timeControl, blocked && style.disabled)}
                                                    data-test-id={`AudioPlayer.TimeSkip10s`}
                                                    aria-label={forwardButtonAriaLabel}
                                                    title={forwardButtonAriaLabel}
                                                    onClick={() => {
                                                        brightcoveElement
                                                            ? (brightcoveElement.currentTime =
                                                                  brightcoveElement.currentTime + 10)
                                                            : (audio.currentTime = audio.currentTime + 10);
                                                    }}
                                                >
                                                    <Icon
                                                        icon={rtl ? jnjBack10Seconds : jnjSkip10Seconds}
                                                        type="jnj"
                                                        color={blocked ? "disabled" : "text-light"}
                                                    />
                                                </button>
                                            )}
                                        </div>
                                        {(playerLarge && !jnjFullBranded) || (isOnDetailPage && jnjFullBranded) ? (
                                            <div className={classnames(style.duration)}>
                                                <Typography
                                                    variant="body"
                                                    color={blocked ? "disabled" : "light"}
                                                    size="s"
                                                >
                                                    <span ref={durationRef}>0:00 </span> /{" "}
                                                    {convertSecondsToTimeString(duration)}
                                                </Typography>
                                            </div>
                                        ) : (
                                            <div className={style.duration}>
                                                <Typography
                                                    variant="body"
                                                    color={blocked ? "disabled" : "light"}
                                                    size="s"
                                                >
                                                    -
                                                    <span ref={offsetRef}>
                                                        {convertSecondsToTimeString(duration) || "0:00"}
                                                    </span>
                                                </Typography>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            }}
        </ResizeObserver>
    );
};
const playerUpdated = (prevProps, nextProps) =>
    nextProps?.src === nextProps?.src ||
    nextProps?.getLength === nextProps?.getLength ||
    nextProps?.getCurrentTime === nextProps?.getCurrentTime ||
    nextProps?.brightcove_id === nextProps?.brightcove_id ||
    nextProps?.isPlayerAlwaysExpanded === nextProps?.isPlayerAlwaysExpanded ||
    nextProps?.playButtonAriaLabel === nextProps?.playButtonAriaLabel ||
    nextProps?.pauseButtonAriaLabel === nextProps?.pauseButtonAriaLabel ||
    nextProps?.replayButtonAriaLabel === nextProps?.replayButtonAriaLabel ||
    nextProps?.backButtonAriaLabel === nextProps?.backButtonAriaLabel ||
    nextProps?.forwardButtonAriaLabel === nextProps?.forwardButtonAriaLabel;

export const AudioPlayer = React.memo(AudioPlayerComponent, playerUpdated);

export default AudioPlayer;
